<script setup lang="ts">
import { useAuthStore } from '~/stores/auth.store';
import { useSiteStore } from '~/stores/site.store';
import { useRgStore } from '~/stores/responsibleGaming.store';
import { useBonusService } from '~/composables/services/useBonusService';
import type { CasinoBonus } from '~/interfaces/dto/bonus/response';
import Banking from '~/components/banking/banking.vue';

const authStore = useAuthStore();
const site = useSiteStore();
const rg = useRgStore();
const bonusService = useBonusService();

onBeforeMount(() => {
  if (
    authStore.getComplianceStatus < 1 &&
    site.getFeatureFlags.get('myaccount.enablecompliance')
  ) {
    authStore.setAccountModalPage('document-verification');
   } else {
    doWithdrawalChecks();
   }
});
function doWithdrawalChecks() {
  if (!rg?.allowWithdrawal) {
    setTimeout(() => {
      authStore.toggleAccountModal(false);
    }, 250);
    site.activateModal('accountRestricted');
  } else {
    getBonusStatus();
  }
}

function getBonusStatus() {
  try {
    bonusService.getBonusStack().then((data) => {
      if (data) {
        if (
          data?.activeCasinoBonus?.totalAwardedBonusAmount ||
          checkForPendingBonuses(data?.pendingCasinoBonuses)
        ) {
          site.activateModal('withdrawalAlert');
        }
      }
      return;
    });
  } catch (e) {
    console.error(e);
  }
}

function checkForPendingBonuses(pendingBonuses: CasinoBonus[]) {
  if (pendingBonuses.length > 0) {
    for (let i = 0; i < pendingBonuses.length; i++) {
      if (pendingBonuses[i]?.totalAwardedBonusAmount > 0) {
        return true;
      }
    }
  }
  return false;
}
</script>
<template>
  <div>
    <LazyUserAccountSettingsHeader
      :title="`${$t('withdrawal')} (${
        authStore.currentUser.primaryMobileNumber
      })`"
      return-route="teller"
      show-balance-mobile
    />
    <div class="px-3 hidden md:block">
      <UserBalances
        class="w-full"
        display-balance-toggle
        display-cash-balance
        display-bonus-balance
        display-total-balance
        stretch
        account-options-modal-style
        single-line
      />
    </div>
    <Banking type="Withdrawals" />
  </div>
</template>
